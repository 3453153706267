<template>
    <section>
    <pageLoader v-if="ploader" :loadingText="'En cours de chargement'"/>

        <div class="page-header">
            <h3 class="page-title"> Ajouter une Compagnie </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">Compagnie</a></li>
                <li class="breadcrumb-item active" aria-current="page">Ajouter une Compagnie</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form class="forms-sample" @submit.prevent="handleSubmit()">
                          <h3>Identités de la société</h3>
                            <!-- nom de la compagnie -->
                              <b-form-group id="name" label="Nom de la compagnie" label-for="name">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="name"
                                  :state="$v.name.$dirty ? !$v.name.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                            <!--  Type de service -->
                              <b-form-group id="type" label="Type de service fourni" label-for="type">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="type"
                                  :state="$v.type.$dirty ? !$v.type.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                            <!-- Email -->
                              <b-form-group id="email" label="Email" label-for="email">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="email"
                                  :state="$v.email.$dirty ? !$v.email.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                            <!-- Téléphone -->
                              <b-form-group id="phone" label="Téléphone" label-for="phone">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="phone"
                                  :state="$v.phone.$dirty ? !$v.phone.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              
                              <h3>Adresse</h3>
                              <!-- Adresse, siège sociale -->
                              <b-form-group id="line1" label="Adresse" label-for="line1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="line1"
                                  :state="$v.line1.$dirty ? !$v.line1.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- Adresse-2 si l'entreprise en a -->
                              <b-form-group id="line2" label="Adresse secondaire" label-for="line2">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="line2"
                                ></b-form-input>
                              </b-form-group>

                              <!-- Ville -->
                              <b-form-group id="city" label="Ville" label-for="city">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="city"
                                  :state="$v.city.$dirty ? !$v.city.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <!-- Code postal -->
                              <b-form-group id="postal_code" label="Code postal" label-for="postal_code">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="postal_code"
                                  :state="$v.postal_code.$dirty ? !$v.postal_code.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <!-- Département -->
                              <b-form-group id="state" label="Département" label-for="state">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="state"
                                  :state="$v.state.$dirty ? !$v.state.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <!-- Pays -->
                              <b-form-group id="country" label="Pays" label-for="country">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="country"
                                  :state="$v.country.$dirty ? !$v.country.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <h3>Informations légales et juridiques</h3>
                              <!-- Numéro SIRET -->
                              <b-form-group id="siret" label="SIRET" label-for="siret">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="siret"
                                  :state="$v.siret.$dirty ? !$v.siret.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                            <!-- Numéro SIREN -->
                              <b-form-group id="siren" label="SIREN" label-for="siren">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="siren"
                                  :state="$v.siren.$dirty ? !$v.siren.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                            <!-- Numéro TVA -->
                              <b-form-group id="tva" label="Numéro TVA" label-for="tva">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="tva"
                                  :state="$v.tva.$dirty ? !$v.tva.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                            <!-- Numéro RCS -->
                              <b-form-group id="rcs" label="Numéro RCS" label-for="rcs">
                                <b-form-input
                                  id="example-input-1"
                                  class="length-sm"
                                  name="example-input-1"
                                  v-model="rcs"
                                  :state="$v.rcs.$dirty ? !$v.rcs.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <hr>
                              <div class="d-flex justify-content-end">
                                  <button class="btn btn-link" @click="company()">Annuler</button>
                                  <button type="submit" class="btn btn-success">Valider</button>
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required} from 'vuelidate/lib/validators'
import pageLoader from '../../components/pageLoader.vue'

export default {
    name: "add-company",
    components:{pageLoader},
    mixins: [validationMixin],
    data(){
        return {
            name:'',
            type:'',
            email:'',
            phone:'',
            line1:'',
            line2:'',
            city:'',
            postal_code:'',
            state:'',
            country:'',
            siret:'',
            siren:'',
            tva:'',
            rcs:'',
            ulid:null,
            ploader:false
        }
    },
    validations: {
        name:{
            required
        },
          type:{
            required
          },
        email:{
          required
        },
        phone:{
          required
        },
        line1:{
          required
        },
        city:{
          required
        },
        postal_code:{
          required
        },
        state:{
          required
        },
        country:{
            required
        },
        siret:{
            required
        },
        siren:{
            required
        },
        tva:{
            required
        },
        rcs:{
          required
        }
    },
    methods: {
        handleSubmit(){
            this.$v.$touch()
            if (this.$v.$anyError) {
            return
            }
            /**
            * post/companies json body
            */
            let body= { 
              name:this.name,
              type:this.type,
              address:{
                  line1 : this.line1,
                  line2 : this.line2,
                  city : this.city,
                  postal_code :this.postal_code,
                  state : this.state,
                  country : this.country
              },
              infos:{
                  siren:this.siren,
                  siret:this.siret,
                  tva:this.tva,
                  rcs:this.rcs
              },
              email:this.email,
              tel:this.phone
          }
          if (this.ulid) {
            /**
            *put/companies axios
            */
            let url ="companies/"+this.ulid
            let method ="put"
            this.requestCompany(method,url,body)
          }else{
            /**
            *post/companies axios
            */
            let url ="companies"
            let method ="post"
            this.requestCompany(method,url,body)
          }          
        },
        requestCompany(method,url,body){
        this.ploader= true
          axios[method](
            url,
            body,
            {
              headers: {
                "X-AUTH-TOKEN": localStorage.getItem("token")
              },
            }
          )
          .then(
            ()=>{
              this.ploader= false
              method == "put"?this.company():method == "post"?this.$snotify.info("Ajouté avec succès")&&this.company():1
            }
          )
          .catch(
            err => {
              this.ploader= false
              console.error(method,err)
            }
          )
      },
      company(){
          this.$router.push({
              name:"company"
          })
      },
      getCompany(){
        this.ploader= true
        axios.get("companies/"+this.ulid)
          .then(
            result =>{
              this.name= result.data.name
              this.type= result.data.type
              this.line1= result.data.address.line1
              this.line2= result.data.address.line2
              this.city= result.data.address.city
              this.postal_code= result.data.address.postal_code
              this.state= result.data.address.state
              this.country= result.data.address.country
              this.siren= result.data.infos.siren
              this.siret= result.data.infos.siret
              this.tva= result.data.infos.tva
              this.rcs= result.data.infos.rcs
              this.email= result.data.email
              this.phone= result.data.tel
              this.ploader= false
            }
          )
          .catch(
            errgetCompany => {
              console.error({errgetCompany})
              return
            }
          )
      }
    },
    mounted(){
      this.ulid = this.$route.params.ulid;
      if (this.ulid) {
        this.getCompany()
      }
    }
}
</script>
<style scoped>
.length-sm{
    width: 50%;
}
</style>